import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import '../agStyles.css';
import { Helmet } from 'react-helmet';
import { Toaster, toast } from 'sonner';
import '../antdesigndatestyles.css'
import DiscordIcon from '../images/discord_icon_blue.png';
import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Alert, Button as AntButton, DatePicker } from 'antd';
import { collection, query, orderBy, onSnapshot, doc, deleteDoc, getDocs, where, addDoc, serverTimestamp, limit, startAfter, updateDoc, setDoc } from "firebase/firestore";
import { firestore } from '../firebase';
import getDateString, { analyticsLogError, convertOptionsSymbol, convertToCSV, daysUntilCanceled, unpackAndCompleteTrade, formatNumber, isTradingDay, isTradingHours, triggerCSVDownload, formatMoney, miniUnpackAndCompleteTrade } from '../Utils';
import MarketDetailsComponent from './MarketDetailsComponent';
import { CurrentVolumeCellRenderer, DeltaCellRenderer, GuestLockCellRenderer, ImpliedVolatilityCellRenderer, OTMCellRenderer, OpenInterestCellRenderer, OptionTypeCellRenderer, PremiumCellRenderer, SigScoreCellRenderer, SpeadExecutionCellRenderer, TickerCellRenderer, TickerSentimentCellRenderer, TradePriceCellRenderer, TradeSizeCellRenderer, TradeTypeCellRenderer, UnixTimestampCellRenderer } from '../MainDashboardCellRendering';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import checkAlertsWithTrade from '../CheckAlertWithTrade';
import FilterIconButton from './FilterIconButton';
import ResetIconButton from './ResetIconButton';
import { TickerSearchBox } from './TickerSearchBox';
import PulsingDot from './PulsingDotComponent';
import QuickFilterAndFavoritesComponent from './ChipFilterComponent';
import CallPutCheckBoxes from '../CallPutCheckBoxes';
import SentimentCheckboxes from '../DashboardComponents/SentimentCheckboxes';
import TradesSweepsCheckBoxes from '../TradesSweepsCheckBoxes';
import ExecutionPriceCheckboxes from '../ExecutionPriceCheckboxes';
import PremiumFilter from '../PremiumFilter';
import ScoreFilterFields from '../ScoreFields';
import TickerFilter from '../TickerFilterFields';
import ExpirationFilterFields from '../ExpirationFilterFields';
import OTMFilterFields from '../OTMFilterFields';
import SpotPriceFields from '../SpotPriceFields';
import OptionPriceFields from '../OptionPriceFields';
import MarketCapFields from '../MarketCapFields';
import Last7DaysBullflowVolume from '../DashboardComponents/BF7DayVolumeFilter';
import PrevDayBullflowVolume from '../DashboardComponents/BFPrevDayVolumeFilter';
import CurrentVolumeFilter from '../DashboardComponents/CurrentVolumeFilter';
import OIFilter from '../DashboardComponents/OIFilter';
import addEventToFirestore from '../EventHelper';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tab, Tabs, Image } from '@nextui-org/react';
import AlertsListView from '../AlertsListView';
import { BarChart, FileText, Gem, LineChartIcon, Crosshair, NewspaperIcon, Star, Search, Download, ArrowUpDown } from 'lucide-react';
import Sidebar from '../Sidebar';
import TextButton from '../components/ui/BullflowTextButton';
import { GenericSearchBox } from './GenericSearchBox';
import TradingViewWidget from '../DashboardComponents/TradingViewWidget';
import { Dialog, DialogContent, styled } from '@material-ui/core';
import OptionPriceChart from '../OptionPriceChart';
import NoSubTopBarComponent from './NoSubTopBarComponent';
import { Tooltip } from "@nextui-org/react";
import SoundIconButton from './SoundIconButton';
import { useMediaQuery } from 'react-responsive';
import Bull from '../bullflow_3.png';
import ClearIsolatedContractButton from './ClearIsolatedContractButton';
import { addUserFavTicker } from '../FirestoreFunctions/Functions';
import TradeSizeFields from '../DashboardComponents/TradeSizeFilter';
import DownloadIconButton from './DownloadIconButton';
import NetPremiumChart from '../NetPremiumChart';

const MAX_LAST_7_DAYS_BF_VOLUME = 500;
const MAX_PREV_DAY_BF_VOLUME = 250;
const MAX_PREVIOUS_VOLUME = 1000000;
const MAX_CURRENT_VOLUME = 1000000;
const MAX_OI = 1000000;
const secondsCalc = (1000 * 3600 * 24);
const today = new Date();
const sectors = ["ALL", "Industrials", "Financial Services", "Consumer Cyclical", "Real Estate", "Basic Materials", "Other", "Technology", "Energy", "Consumer Defensive", "Communication Services", "Healthcare", "Utilities"]
const gridOptions = {
    getRowNodeId: data => data.id,
    getRowId: params => params.data.id,
    headerHeight: 20, // Set the header row height to 50 pixels
};
const MyDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        backgroundColor: 'black',
        borderRadius: '10px',
        minWidth: '300px',
        maxWidth: '90%',
        width: 'auto',
        height: 'auto',
        overflowY: 'auto',
        zIndex: 10000
    },
});

function MainDashboard() {
    const navigate = useNavigate();
    const auth = getAuth();
    const { RangePicker } = DatePicker;
    const user = auth.currentUser;
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const toggleDrawer = () => setIsFilterDrawerOpen(!isFilterDrawerOpen);
    const [trades, setTrades] = useState([]);
    const isMountedRef = useRef(true);
    const processedTradeIdsRef = useRef(new Set());
    const unsubscribeRef = useRef(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const setProcessedTradeIds = useCallback((newSet) => {
        processedTradeIdsRef.current = newSet;
    }, []);
    const gridApiRef = useRef(null);
    const columnApiRef = useRef(null);
    const myDivRef = useRef();
    const [localFavoritesList, setLocalFavoritesList] = useState([]);
    const [expirationUnixStart, setExpirationUnixStart] = useState(0);
    const [expirationUnixEnd, setExpirationUnixEnd] = useState(9999999999999);
    const [isolatedSymbol, setIsolatedSymbol] = useState("");
    const [minPremium, setMinPremium] = useState(9999);
    const [maxPremium, setMaxPremium] = useState(500000000);
    const [excludeTickers, setExcludeTickers] = useState([]);
    const [onlyShowTickers, setOnlyShowTickers] = useState([]);
    const [minDTE, setMinDTE] = useState(0);
    const [maxDTE, setMaxDTE] = useState(1200);
    const [minOTM, setMinOTM] = useState(-3000);
    const [maxOTM, setMaxOTM] = useState(3000);
    const [minScore, setMinScore] = useState(0.0);
    const [maxScore, setMaxScore] = useState(1.0);
    // const [maxLast7DaysBFVolume, setMaxLast7DaysBFVolume] = useState(MAX_LAST_7_DAYS_BF_VOLUME);
    // const [maxPrevDayBFVolume, setMaxPrevDayBFVolume] = useState(MAX_PREV_DAY_BF_VOLUME);
    const [maxPreviousVolume, setMaxPreviousVolume] = useState(MAX_PREVIOUS_VOLUME);
    const [maxCurrentVolume, setMaxCurrentVolume] = useState(MAX_CURRENT_VOLUME);
    const [maxOI, setMaxOI] = useState(MAX_OI);
    const [fetchingRemainingTrades, setFetchingRemainingTrades] = useState(true);
    const [minSpot, setMinSpot] = useState(0);
    const [maxSpot, setMaxSpot] = useState(7500);
    const [isSoundOn, setIsSoundOn] = useState(true);
    const [minOptionPrice, setMinOptionPrice] = useState(0);
    const [maxOptionPrice, setMaxOptionPrice] = useState(1000000);
    const [minTradeSize, setMinTradeSize] = useState(0);
    const [maxTradeSize, setMaxTradeSize] = useState(1000000);
    const [minVolumeDif, setMinVolumeDif] = useState(null);
    const [maxVolumeDif, setMaxVolumeDif] = useState(null);
    const [minMarketCap, setMinMarketCap] = useState(0);
    const [maxMarketCap, setMaxMarketCap] = useState(99999999999999);
    const [tradeInfoDialog, setTradeInfoDialog] = useState(false);
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openSaveAlertDialog, setOpenSaveAlertDialog] = useState(false);
    const [openOptionPriceChart, setOpenOptionPriceChart] = useState(false);
    const [openNetPremiumChart, setOpenNetPremiumChart] = useState(false);
    const [openSpotTradingView, setOpenSpotTradingView] = useState(false);
    const [tradingViewTicker, setTradingViewTicker] = useState("");
    const [optionPriceChartTicker, setOptionPriceChartTicker] = useState("");
    const [accountSettingsDialog, setAccountSettingsDialog] = useState(false);
    const [callsCheckBox, setCallsCheckBox] = useState(true);
    const [putsCheckBox, setPutsCheckBox] = useState(true);
    const [aaCheckBox, setAACheckBox] = useState(true);
    const [bbCheckBox, setBBCheckBox] = useState(true);
    const [bullishCheckBox, setBullishCheckBox] = useState(true);
    const [bearishCheckBox, setBearishCheckBox] = useState(true);
    const [neutralCheckBox, setNeutralCheckBox] = useState(true);
    const [spCheckBox, setSpCheckBox] = useState(true);
    const [splitsCheckBox, setSplitsCheckBox] = useState(true);
    const [sweepsCheckBox, setSweepsCheckBox] = useState(true);
    const [blocksCheckbox, setBlocksCheckBox] = useState(true);
    const [multilegCheckbox, setMultilegCheckbox] = useState(false);
    const [singlesCheckbox, setSinglesCheckBox] = useState(true);
    const [filterNameValue, setFilterNameValue] = useState("");
    const [alertNameValue, setAlertNameValue] = useState("");
    const [selectedTickerForInfo, setSelectedTickerForInfo] = useState(null);
    const [filterItems, setFilterItems] = useState([]);
    const [alertItems, setAlertItems] = useState([]);
    const alertItemsRef = useRef({});
    const [selectedItem, setSelectedItem] = useState("Filters");
    const [filterNames, setFilterNames] = useState([]);
    const [userHasSubscription, setUserHasSubscription] = useState(true);
    const [alreadyDeterminedUserSubscriptionState, setAlreadyDeterminedUserSubscriptionState] = useState(false);
    const [contractPercentChange, setContractPercentChange] = useState(null);
    const [contractPreviousVolume, setContractPreviousVolume] = useState(null);
    const [contractBidAskSpread, setContractBidAskSpread] = useState(["", "", ""]);
    const [contractCurrentValue, setContractCurrentValue] = useState(null);
    const [sectorFilter, setSectorFilter] = useState("ALL");
    const [isFiltering, setIsFiltering] = useState(false);
    const [showAlertsView, setShowAlertsView] = useState(false);
    const [cancelAt, setCancelAt] = useState(null);
    const [activeTags, setActiveTags] = useState([]);
    const [activeFavoriteTickers, setActiveFavoriteTickers] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [showRowDropdown, setShowRowDropdown] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
    const [tickerForData, setTickerForData] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [filterSelectValue, setFilterSelectValue] = useState("");
    const [firstTimeLoading, setFirstTimeLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isScrollPositionTop, setIsScrollPositionTop] = useState(true);
    const [expirationStartDate, setExpirationStartDate] = useState(null);
    const [expirationEndDate, setExpirationEndDate] = useState(null);
    const [tradeDetailsData, setTradeDetailsData] = useState(null);
    const [showTradeDetailsDialog, setShowTradeDetailsDialog] = useState(false);
    const [isDiscordModalOpen, setIsDiscordModalOpen] = useState(false);
    const lastScrollTopRef = useRef(0);
    const gridDivRef = useRef(null);
    const gridStyle = useMemo(() => ({
        height: 'calc(100vh - 20px)', width: '100%', transition: 'all 0.3s ease', marginTop: '10px',
        overflow: 'hidden'
    }), []);
    const showSignupNotif = () => {
        toast('Start your free trial to access this feature', {
            duration: 7500,
            style: {
                background: 'black',
                color: 'white',
            },
            action: {
                label: 'Let\'s Go',
                onClick: () => navigate('/signup'),
            },
            icon: <Gem size={18} />,
        });
    };
    const onRowClicked = (params) => {
        const rowData = params.data;
        setSelectedRowData(rowData);
        setShowRowDropdown(true);
        window.gtag('event', 'Click trade row', {
            'event_category': 'Button',
            'event_label': 'Click trade row',
            'value': 1
        });
        addEventToFirestore("Click trade row", user && user.email ? user.email : "N/A");
        // Get the viewport height
        const viewportHeight = window.innerHeight;

        // Calculate the distance from the bottom of the screen
        const distanceFromBottom = viewportHeight - params.event.clientY;

        // Set a threshold value for the distance from the bottom
        const threshold = 300; // Adjust this value according to your needs

        // If the distance from the bottom is less than the threshold, move the dropdown up
        if (distanceFromBottom < threshold) {
            setDropdownPosition({
                x: params.event.clientX,
                y: params.event.clientY - threshold,
            });
        } else {
            setDropdownPosition({
                x: params.event.clientX,
                y: params.event.clientY,
            });
        }
    };

    const closeRowDropdown = () => {
        setShowRowDropdown(false);
    };

    function tradePriceClickHandler(gtag, addEventToFirestore, setOpenOptionPriceChart, setOptionPriceChartTicker, params) {
        gtag('event', 'Click trade price', {
            'event_category': 'Button',
            'event_label': 'Click trade price',
            'value': 1
        });
        addEventToFirestore("Open option volume chart");
        setOpenOptionPriceChart(true);
        setOptionPriceChartTicker(params.row.sym);
    };

    function openNetPremiumHandler(gtag, addEventToFirestore, setOpenNetPremiumChart, setOptionPriceChartTicker, params) {
        gtag('event', 'Click net premium', {
            'event_category': 'Button',
            'event_label': 'Click net premium',
            'value': 1
        });
        addEventToFirestore("Open Net Premium Chart");
        setOpenNetPremiumChart(true);
        setOptionPriceChartTicker(params.row.sym);
    };



    function spotClickHandler(gtag, addEventToFirestore, setOpenSpotTradingView, setTradingViewTicker, params) {
        gtag('event', 'Click spot price', {
            'event_category': 'Button',
            'event_label': 'Click spot price',
            'value': 1
        });
        addEventToFirestore("Open Trading View Chart");
        setOpenSpotTradingView(true);
        setTradingViewTicker(params.row.tsy);
    }

    const onTradePriceClick = useCallback((params) => () => {
        tradePriceClickHandler(window.gtag, addEventToFirestore, setOpenOptionPriceChart, setOptionPriceChartTicker, params);
    }, [addEventToFirestore, setOpenOptionPriceChart, setOptionPriceChartTicker]);

    const onNetPremiumChartClicked = useCallback((params) => () => {
        openNetPremiumHandler(window.gtag, addEventToFirestore, setOpenNetPremiumChart, setOptionPriceChartTicker, params);
    }, [addEventToFirestore, setOpenOptionPriceChart, setOptionPriceChartTicker]);

    const openTradingViewChart = useCallback((params) => {
        spotClickHandler(window.gtag, addEventToFirestore, setOpenSpotTradingView, setTradingViewTicker, params);
    }, [addEventToFirestore, setOpenSpotTradingView, setTradingViewTicker]);

    const DropdownMenu = ({ position, onClose }) => {
        const items = [
            { key: "isolateStock", label: "Isolate Ticker", icon: <Search size={16} /> },
            { key: "isolate", label: "Isolate Contract", icon: <Crosshair size={16} /> },
            { key: "favorite", label: "Favorite Ticker", icon: <Star size={16} /> },
            { key: "chart", label: "Option Chart", icon: <BarChart size={16} /> },
            { key: "details", label: "Trade Details", icon: <FileText size={16} /> },
            { key: "news", label: "News", icon: <NewspaperIcon size={16} /> },
            { key: "ticker chart", label: "TradingView Chart", icon: <LineChartIcon size={16} /> },
            { key: "netpremium", label: "Net Premium Chart", icon: <ArrowUpDown size={16} /> },
        ];

        const handleItemClick = (item) => {
            if (userHasSubscription === false) {
                showSignupNotif();
                onClose();
                return;
            }
            switch (item.key) {
                case "details":
                    openTradeInfo(selectedRowData);
                    break;
                case "netpremium":
                    onNetPremiumChartClicked({ row: selectedRowData })();
                    break;
                case "chart":
                    onTradePriceClick({ row: selectedRowData })();
                    break;
                case "news":
                    window.open(`https://www.benzinga.com/quote/${selectedRowData.tsy}/news`, '_blank');
                    break;
                case "ticker chart":
                    openTradingViewChart({ row: selectedRowData });
                    break;
                case "isolate":
                    gridApiRef.current.ensureIndexVisible(0, 'top');
                    setIsolatedSymbol(selectedRowData.sym);
                    break;
                case "favorite":
                    addUserFavTicker(user.email, selectedRowData.tsy);
                    setLocalFavoritesList([...localFavoritesList, selectedRowData.tsy]);
                    break;
                case "isolateStock":
                    gridApiRef.current.ensureIndexVisible(0, 'top');
                    setIsolatedSymbol("");
                    setTickerForData(selectedRowData.tsy);
                    setOnlyShowTickers([selectedRowData.tsy]);
                    setSearchInputValue(selectedRowData.tsy);
                    break;
                default:
                    break;
            }
            onClose();
        };

        return (
            <div
                style={{
                    position: 'absolute',
                    top: position.y,
                    left: position.x,
                    backgroundColor: '#1c1c1c',
                    color: 'white',
                    borderRadius: '12px',
                    padding: '10px',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    zIndex: 100,
                    fontWeight: 550,
                }}
            >
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1px 1fr', // 1px for the divider column
                        gap: '10px',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {items.slice(0, Math.ceil(items.length / 2)).map((item) => (
                            <div
                                key={item.key}
                                style={{
                                    padding: '8px 0',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={() => handleItemClick(item)}
                            >
                                <span style={{ marginRight: '4px' }}>{item.icon}</span> {/* Reduced the margin */}
                                {item.label}
                            </div>
                        ))}
                    </div>

                    <div style={{ backgroundColor: '#3a3a3a', width: '100%', height: '100%' }}></div> {/* Dark grey divider */}

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {items.slice(Math.ceil(items.length / 2)).map((item) => (
                            <div
                                key={item.key}
                                style={{
                                    padding: '8px 0',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={() => handleItemClick(item)}
                            >
                                <span style={{ marginRight: '4px' }}>{item.icon}</span> {/* Reduced the margin */}
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
                <button
                    onClick={onClose}
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        border: 'none',
                        borderRadius: '12px',
                        padding: '5px 10px',
                        marginTop: '10px',
                        cursor: 'pointer',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    Close
                </button>
            </div>
        );
    };


    const LivePerformanceCellRenderer = (params) => {
        const optionsTicker = params?.data?.sym;
        const oldSpread = params?.data?.tp;
        const { data, loading } = useAsyncPerformance(optionsTicker, oldSpread);


        if (loading) {
            return <p />;
        }


        // Inline function to determine color
        const getColor = (value) => {
            if (value >= 0) return '#20d46b';
            if (value < 0) return '#cc3753';
            return 'inherit'; // Default color if value is neither positive nor negative
        };

        // Determine the display value
        const displayValue = (data || data === 0) ? `${data}%` : "";

        return (
            <Tooltip delay={1250} content="Performance since trade">
                <div style={{ color: getColor(data), fontWeight: 550 }}>
                    {displayValue}
                </div>
            </Tooltip>
        );
    };

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }
    const useAsyncPerformance = (optionsTicker, oldSpread) => {
        const [data, setData] = useState(null);
        const [loading, setLoading] = useState(false);
        // Debounced fetchData function
        const fetchData = useCallback(debounce(async (optionsTicker, oldSpread) => {
            setLoading(true);
            const result = await fetchOptionsSpread(optionsTicker, oldSpread); // Ensure fetchOptionsSpread is defined or imported
            if (result != null) {
                setData(result[0]);
            }
            setLoading(false);
        }, 500), [optionsTicker, oldSpread]);

        useEffect(() => {
            if (optionsTicker && oldSpread) {
                fetchData(optionsTicker, oldSpread);
            }
        }, [optionsTicker, oldSpread, fetchData]);

        return { data, loading };
    };

    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'ut',
            width: 87.5,
            headerName: 'Time',
            suppressMenu: true,
            cellRenderer: UnixTimestampCellRenderer,
            sort: 'desc',
            editable: false,
        },
        {
            field: 'p', headerName: 'Value', width: 85,
            editable: false,
            cellRenderer: PremiumCellRenderer
        },
        {
            field: 'tsy', headerName: 'Ticker', cellRenderer: TickerCellRenderer,
            width: 92.5,
            cellStyle: { fontSize: '11px', fontWeight: 600 },
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'sp', headerName: 'Spot', width: 85,
            editable: false,
        },
        {
            field: 'stp', headerName: 'Strike', width: 75,
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'ot', headerName: 'PC',
            cellRenderer: OptionTypeCellRenderer, width: 70,
            editable: false,
        },
        {
            field: 'ed', headerName: 'Exp.',
            width: 90,
            editable: false,
        },
        {
            field: 'se', headerName: 'X', width: 70,
            editable: false,
            cellRenderer: SpeadExecutionCellRenderer
        },
        {
            field: 'tt', headerName: 'Type',
            width: 80,
            cellRenderer: TradeTypeCellRenderer,
            editable: false,
        },
        {
            field: 'tp', headerName: 'Price',
            width: 85,
            editable: false,
        },
        {
            field: 'ts', headerName: 'Size',
            editable: false,
            cellRenderer: TradeSizeCellRenderer,
            width: 75
        },
        {
            field: 'sc', headerName: 'SigScore', width: 107.5,
            editable: false,
            cellRenderer: SigScoreCellRenderer
        },
        {
            field: 'g', headerName: 'Δ',
            cellRenderer: DeltaCellRenderer, width: 72.5,
            editable: false,
        },
        {
            field: 'cv', headerName: 'Volume', width: 95,
            editable: false,
            cellRenderer: CurrentVolumeCellRenderer
        },
        {
            field: 'oi', headerName: 'OI', width: 70,
            editable: false,
            cellRenderer: OpenInterestCellRenderer
        },
        {
            field: 'iv', headerName: 'IV', width: 70,
            editable: false,
            cellRenderer: ImpliedVolatilityCellRenderer
        },
        {
            field: 'op', headerName: 'OTM', width: 80,
            editable: false,
            cellRenderer: OTMCellRenderer
        },
        {
            field: 'tks', headerName: 'Sentiment',
            width: 100, suppressMenu: true, wrapHeaderText: true,
            cellRenderer: TickerSentimentCellRenderer, editable: false,
        },
        {
            field: 'lp', headerName: '% Return', width: 125,
            sortable: false,
            editable: false, cellRenderer: LivePerformanceCellRenderer
        },
        {
            field: 'edf', headerName: 'Earnings', width: 110,
            editable: false, sortable: false, suppressMenu: true,
        },
    ]);

    const guestColumnDefs = [
        {
            field: 'ut',
            width: 85,
            headerName: 'Time',
            suppressMenu: true,
            cellRenderer: UnixTimestampCellRenderer,
            sort: 'desc',
            editable: false,
            sortable: false,
        },
        {
            field: 'tsy', headerName: 'Ticker',
            cellRenderer: TickerCellRenderer,
            width: 92.5,
            cellStyle: { fontSize: '11px', fontWeight: 600 },
            suppressMenu: true,
            editable: false,
            sortable: false,
        },
        {
            field: 'ot', headerName: 'P/C',
            cellRenderer: GuestLockCellRenderer, width: 50,
            editable: false,
            sortable: false,
        },
        {
            field: 'ed', headerName: 'Expiration',
            width: 115,
            editable: false,
            sortable: false,
        },
        {
            field: 'tt', headerName: 'Type',
            width: 80,
            cellRenderer: TradeTypeCellRenderer,
            editable: false,
            sortable: false,
        },
        {
            field: 'tks', headerName: 'Sentiment',
            width: 100,
            suppressMenu: true,
            wrapHeaderText: true,
            cellRenderer: GuestLockCellRenderer,
            editable: false,
            sortable: false,
        },
        {
            field: 'ts', headerName: 'Size',
            editable: false,
            width: 75,
            sortable: false,
            cellRenderer: GuestLockCellRenderer,
        },
        {
            field: 'tp', headerName: 'Price',
            width: 85, cellRenderer: GuestLockCellRenderer,
            editable: false,
            sortable: false,
        },
        {
            field: 'stp', headerName: 'Strike', width: 85,
            editable: false,
            sortable: false,
            cellRenderer: GuestLockCellRenderer,
        },
        {
            field: 'op', headerName: 'OTM', width: 80,
            editable: false,
            sortable: false,
            cellRenderer: GuestLockCellRenderer,
        },
        {
            field: 'p', headerName: 'Value', width: 85,
            editable: false,
            sortable: false,
            cellRenderer: PremiumCellRenderer,
        },
        {
            field: 'se', headerName: 'X', width: 80,
            editable: false,
            sortable: false,
            cellRenderer: GuestLockCellRenderer,
        },
        {
            field: 'sc', headerName: 'SigScore', width: 107.5,
            editable: false,
            sortable: false,
            cellRenderer: GuestLockCellRenderer,
        },
        {
            field: 'lp', headerName: '% Return', width: 125,
            sortable: false,
            editable: false, cellRenderer: GuestLockCellRenderer
        },
        {
            field: 'sp', headerName: 'Spot', width: 85,
            editable: false,
            sortable: false,
        },
        {
            field: 'cv', headerName: 'Volume', width: 90,
            editable: false,
            sortable: false,
            cellRenderer: GuestLockCellRenderer,
        },
        {
            field: 'oi', headerName: 'OI', width: 70,
            editable: false,
            sortable: false,
            cellRenderer: GuestLockCellRenderer
        },
        {
            field: 'iv', headerName: 'IV', width: 70,
            editable: false,
            sortable: false,
            cellRenderer: ImpliedVolatilityCellRenderer
        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            editable: true,
            filter: true,
        };
    }, []);

    useEffect(() => {
        // Subscribe to auth changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                if (!alreadyDeterminedUserSubscriptionState) {
                    fetchSubscriptionType(user);
                }
            } else {
                setUserHasSubscription(false);
                setColumnDefs(guestColumnDefs);
            }
        });
        return () => unsubscribe();
    }, []);

    const fetchSubscriptionType = async (user_) => {
        try {
            if (user_ != null) {
                const paymentsCollection = collection(firestore, 'payments');
                const q = query(paymentsCollection, where('user', '==', user_.email));
                const querySnapshot = await getDocs(q);
                // gridApiRef.current.showLoadingOverlay();
                if (querySnapshot.empty) {
                    console.log(`Error 5988, user does not have a subscription. User: ${user_.email}`);
                    setUserHasSubscription(false);
                    setColumnDefs(guestColumnDefs);
                    gridApiRef.current.hideOverlay();
                } else {
                    querySnapshot.forEach((doc) => {
                        let subtype = doc.data().subscriptionType;
                        let canceling = doc.data().canceling;
                        let cancelAt_ = doc.data().cancelAt;
                        let subscriptionStatus = doc.data().subscriptionStatus;
                        if (cancelAt_ != null && canceling != null) {
                            const daysUntilCanceled_ = daysUntilCanceled(cancelAt_);
                            if (canceling === true && daysUntilCanceled_ <= 14) {
                                setCancelAt(daysUntilCanceled_);
                            }
                        }
                        if (subscriptionStatus != null && (subscriptionStatus === "deleted" || subscriptionStatus === "canceled" || subscriptionStatus === "incomplete_expired" || subscriptionStatus === "incomplete" || subscriptionStatus === "past_due" || subscriptionStatus === "unpaid")) {
                            navigate('/pricing');
                        }

                        if (subscriptionStatus != null && (subscriptionStatus === "deleted" || subscriptionStatus === "canceled" || subscriptionStatus === "incomplete_expired" || subscriptionStatus === "incomplete" || subscriptionStatus === "past_due" || subscriptionStatus === "unpaid")) {                            // Open stripe portal
                            navigate('/pricing');
                        }
                        if (subtype != null && (subtype === "yearly" || subtype === "monthly")) {
                            setUserHasSubscription(true);
                            setAlreadyDeterminedUserSubscriptionState(true);
                        } else {
                            setUserHasSubscription(false);
                            setColumnDefs(guestColumnDefs);
                        }
                    });
                }
            } else {
                console.error("error code 19277");
            }
        } catch (error) {
            console.error(`Sub error 391174`);
        }
    };
    useEffect(() => {
        if (user != null) {
            fetchFilters();
            fetchAlerts();
        }
        addEventToFirestore("Dashboard loaded");
    }, [user]);
    const fetchData = useCallback(async (symbol, tradePrice) => {
        setContractPercentChange("N/A");
        setContractCurrentValue("N/A");
        const data = await fetchOptionsSpread(symbol, tradePrice);
        try {
            setContractPercentChange(data[0]);
            setContractCurrentValue(data[1]);
        } catch (error) {
            console.log("Spread error 10062");
            setContractPercentChange("N/A");
            setContractCurrentValue("N/A");
        }
    }, []);
    const fetchPreviousVolumeData = useCallback(async (symbol) => {
        setContractPreviousVolume("N/A");
        const data = await fetchPreviousVolume(symbol);
        try {
            // Check if the data[0] is a number
            if (typeof data[0] === 'number') {
                // console.log("Got previous volume: ", data[0]);
                setContractPreviousVolume(data[0]);
            } else {
                console.log("prev volume error 10061");
                setContractPreviousVolume("N/A");
            }
        } catch (error) {
            console.log("prev volume error 10062");
            setContractPreviousVolume("N/A");
        }
    }, []);

    const fetchTradeDetailsData = useCallback(async (symbol, x, docId) => {
        setTradeDetailsData(null);
        const data = await fetchTradeDetails(symbol, x, docId);
        try {
            setTradeDetailsData(data);
        } catch (error) {
            console.log("Trade details error 29852");
            setTradeDetailsData(null);
        }
    }, []);



    const fetchContractBidAsk = useCallback(async (symbol) => {
        setContractBidAskSpread(["N/A", "N/A", "N/A"]);
        const data = await fetchBidAskSpread(symbol);
        try {
            if (data.length === 3) {
                console.log("Got bid ask: ", data);
                setContractBidAskSpread(data);
            } else {
                console.log("Bid ask error 47221");
                setContractBidAskSpread(["N/A", "N/A", "N/A"]);
            }
        } catch (error) {
            console.log("Bid ask error 47222");
            setContractBidAskSpread(["N/A", "N/A", "N/A"]);
        }
    }, []);



    const openTradeInfo = (trade) => {
        setSelectedTickerForInfo(trade);
        fetchData(trade.sym, trade.tp)
        fetchTradeDetailsData(trade.sym, trade.x, trade.id);
        setTradeInfoDialog(true);
    };
    const fetchAlerts = async () => {
        try {
            if (user != null) {
                const querySnapshot = await getDocs(query(
                    collection(firestore, '_custom_alerts'),
                    where('userEmail', '==', user.email))
                );
                let fetchedItems = {};
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    fetchedItems[data.alertName] = data;
                });

                setAlertItems(fetchedItems);
                alertItemsRef.current = fetchedItems;
            } else {
                console.log("Alert error 6574839");
            }
        } catch (error) {
            console.error('Alert error 3391855: ', error);
        }
    };
    const fetchTrades = useCallback(async () => {
        let orderedTradesRef = null;
        const tradesRef = collection(firestore, "_MIKE_FLOW/options/" + getDateString());
        // const tradesRef = collection(firestore, "_MIKE_FLOW/options/_new_keys_test");
        // const tradesRef = collection(firestore, "_MIKE_FLOW/options/_09_30_24_COPY");
        try {
            // Limit the initial fetch to 5000 documents
            orderedTradesRef = query(tradesRef);
            const today = new Date();
            // Flag to indicate if the initial load has been completed.
            let initialLoadCompleted = false;
            console.log("Fetching trades");
            // Snapshot listener for the initial batch
            unsubscribeRef.current = onSnapshot(orderedTradesRef, (snapshot) => {
                console.log("Snapshot received");
                if (!isMountedRef.current) {
                    console.error("error 3400");
                    return;
                }
                const docChanges = snapshot.docChanges();
                if (docChanges.length === 0) {
                    gridApiRef.current.showNoRowsOverlay();
                }
                // console.log("Doc changes: ", docChanges.length);

                // Temporary arrays to store trades and processed trade IDs
                let addedTrades = [];
                let modifiedTrades = [];
                let removedTradeIds = [];
                let newProcessedTradeIds = new Set(processedTradeIdsRef.current);
                // console.log("Unpacking trades")
                docChanges.forEach((change) => {
                    if (change.type === "added") {
                        let trade = { ...change.doc.data(), id: change.doc.id };
                        trade = miniUnpackAndCompleteTrade(trade);
                        addedTrades.push(trade);

                        // Check alerts only if this is not part of the initial load and the trade hasn't been processed.
                        if (initialLoadCompleted && !processedTradeIdsRef.current.has(trade.id)) {
                            checkAlertsWithTrade(trade, today, secondsCalc, alertItemsRef.current, isSoundOn);
                        }
                        newProcessedTradeIds.add(trade.id);
                    }
                    if (change.type === "modified") {
                        let trade = { ...change.doc.data(), id: change.doc.id };
                        trade["tdn"] = change.doc.id;
                        trade = trade.tdn ? unpackAndCompleteTrade(trade) : trade;
                        modifiedTrades.push(trade);
                    }
                    if (change.type === "removed") {
                        removedTradeIds.push(change.doc.id);
                    }
                });

                // console.log("Setting trades");
                // Batch update the trades state once after processing all docChanges
                setTrades((prevTrades) => {
                    // First, remove the removed trades
                    let updatedTrades = prevTrades.filter((t) => !removedTradeIds.includes(t.id));

                    // Then, add the new trades
                    updatedTrades = [...updatedTrades, ...addedTrades];

                    // Finally, update the modified trades
                    modifiedTrades.forEach((modifiedTrade) => {
                        updatedTrades = updatedTrades.map((t) => (t.id === modifiedTrade.id ? modifiedTrade : t));
                    });

                    return updatedTrades;
                });

                // Set the processed trade IDs after all changes are processed
                setProcessedTradeIds(new Set(newProcessedTradeIds));

                // After the first batch of documents is processed, mark the initial load as completed.
                if (!initialLoadCompleted && docChanges.length > 0) {
                    initialLoadCompleted = true;
                    setFetchingRemainingTrades(false);
                    // fetchRemainingTradesInBackground(snapshot.docs[snapshot.docs.length - 1]);
                }
                if (docChanges.length < 1) {
                    setFetchingRemainingTrades(false);
                }
            });


        } catch (err) {
            console.error("Db error 45672");
        }
    }, []);

    const fetchRemainingTradesInBackground = async (lastVisibleDoc) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        try {
            // console.log('Fetching remaining trades');

            // Query for all remaining documents starting after the last fetched document

            const remainingQuery = query(
                collection(firestore, "_MIKE_FLOW/options/" + getDateString()),
                startAfter(lastVisibleDoc)
            );

            // Get all the remaining documents
            const snapshot = await getDocs(remainingQuery);

            // Combine the new documents with the existing trades state
            const length = snapshot.docs.length;
            console.log(`Fetched ${length} remaining trades.`);
            let trades = snapshot.docs.map((doc) => {
                let trade = { ...doc.data(), id: doc.id };
                trade["tdn"] = doc.id;
                trade = trade.tdn ? unpackAndCompleteTrade(trade) : trade;
                return trade;
            });
            // console.log(`Trades length: ${trades.length}. First trade: ${JSON.stringify(trades[0])}`);
            if (length > 0) {
                setTrades((prevTrades) => [
                    ...prevTrades,
                    ...trades
                ]);
            }
            setFetchingRemainingTrades(false);
        } catch (error) {
            console.error("Error fetching remaining trades:", error);
            setFetchingRemainingTrades(false);
        }
    };



    useEffect(() => {
        fetchTrades();
        return () => {
            isMountedRef.current = false;
            if (unsubscribeRef.current) {
                unsubscribeRef.current();
            }
        };
    }, [fetchTrades]);

    function useDebouncedEffect(effect, deps, delay) {
        useEffect(() => {
            const handler = setTimeout(() => effect(), delay);
            return () => clearTimeout(handler);
        }, [...deps, delay]);
    }

    const updateDataGridRows = () => {
        if (trades.length > 0) {
            setIsFiltering(true);
            setIsLoading(true);
        }

        const today = new Date();
        const thirtyMinAgo = (today.getTime() / 1000) - (30 * 60);
        let filteredTrades = [];

        if (firstTimeLoading) {
            filteredTrades = trades;
        } else {
            filteredTrades = trades.filter(trade => checkTradeFilter(trade, today, secondsCalc, thirtyMinAgo));
        }
        const allGridRowData = [];
        try {
            gridApiRef.current.forEachNode((node) => allGridRowData.push(node.data));
        } catch (error) {
            console.error('Grid error');
        }

        const allRowIdsInGrid = new Set(allGridRowData.map((data) => data.id));
        const filteredTradeIds = new Set(filteredTrades.map((trade) => trade.id));

        const addsAndUpdates = filteredTrades.filter((trade) =>
            !allRowIdsInGrid.has(trade.id) || allRowIdsInGrid.has(trade.id)
        );

        const transaction = {
            add: addsAndUpdates.filter((trade) => !allRowIdsInGrid.has(trade.id)),
            update: addsAndUpdates.filter((trade) => allRowIdsInGrid.has(trade.id)),
            remove: allGridRowData.filter((data) => !filteredTradeIds.has(data.id)),
        };

        if (trades.length > 0) {
            if (firstTimeLoading) {
                const searchParams = new URLSearchParams(window.location.search);
                const ticker = searchParams.get('ticker');
                if (ticker) {
                    const trade = trades.find((trade) => trade.tsy === ticker);
                    setOnlyShowTickers([trade.tsy]);
                }
            }
            setFirstTimeLoading(false);
        }

        if (transaction.add.length > 0 || transaction.update.length > 0 || transaction.remove.length > 0) {
            try {
                const gridApi = gridApiRef.current;
                if (!gridApi) return;
                const firstVisibleRow = gridApi.getFirstDisplayedRow();
                const lastVisibleRow = gridApi.getLastDisplayedRow();
                const isTopRow = firstVisibleRow === 0;

                // Store the current scroll position
                lastScrollTopRef.current = gridApi.getVerticalPixelRange().top;

                // Apply the transaction, this causes a UI update

                // console.log(`Transaction lengths: add: ${transaction.add.length}, update: ${transaction.update.length}, remove: ${transaction.remove.length}`);
                if (!isTopRow && transaction.add.length > 0) {
                    gridApi.applyTransaction(transaction);
                    const indexToKeepInView = Math.floor((firstVisibleRow + lastVisibleRow) / 2);
                    const adjustedIndex = indexToKeepInView + transaction.add.length;
                    // console.log(`Scrolling to index: ${adjustedIndex}`);
                    // Use setTimeout to ensure the DOM has updated
                    setTimeout(() => {
                        // Ensure the index is visible without animation
                        // This also causes a UI
                        gridApi.ensureIndexVisible(adjustedIndex, 'middle');
                    }, 0);
                } else {
                    gridApi.applyTransaction(transaction);
                    // If we're at the top, just resume transactions
                }
            } catch (error) {
                console.error('Grid error:', error);
            }
        }

        setIsLoading(false);
    };


    // Function to save column state to localStorage
    // const saveColumnState = useCallback(() => {
    //     if (columnApiRef.current) {
    //         const columnState = columnApiRef.current.getColumnState();
    //         localStorage.setItem('bullflowGridColumnState', JSON.stringify(columnState));
    //     } else {
    //         console.warn("error 7841121");
    //     }
    // }, []);

    // // Function to load column state from localStorage
    // const loadColumnState = useCallback(() => {
    //     const savedState = localStorage.getItem('bullflowGridColumnState');
    //     if (savedState && columnApiRef.current) {
    //         const columnState = JSON.parse(savedState);
    //         columnApiRef.current.applyColumnState({
    //             state: columnState,
    //             applyOrder: true,
    //         });
    //     } else {
    //         if (savedState) {
    //             console.warn("col error 298414");
    //         } else {
    //             console.log("col error 298415");
    //         }
    //     }
    // }, []);

    // // Event handler for when a column is moved
    // const onColumnMoved = useCallback(() => {
    //     saveColumnState();
    // }, [saveColumnState]);

    const onGridReady = params => {
        console.log("Grid ready");
        gridApiRef.current = params.api;
        columnApiRef.current = params.columnApi;
        // loadColumnState();
    };
    useDebouncedEffect(updateDataGridRows, [
        trades,
        activeFavoriteTickers,
        minPremium,
        maxPremium,
        minOTM,
        maxOTM,
        minScore,
        maxScore,
        // maxLast7DaysBFVolume,
        // maxPrevDayBFVolume,
        maxPreviousVolume,
        maxCurrentVolume,
        maxOI,
        minSpot,
        maxSpot,
        minOptionPrice,
        maxOptionPrice,
        minTradeSize,
        maxTradeSize,
        minMarketCap,
        maxMarketCap,
        minDTE,
        maxDTE,
        excludeTickers,
        onlyShowTickers,
        callsCheckBox,
        putsCheckBox,
        aaCheckBox,
        bbCheckBox,
        bullishCheckBox,
        bearishCheckBox,
        neutralCheckBox,
        spCheckBox,
        splitsCheckBox,
        sweepsCheckBox,
        multilegCheckbox,
        singlesCheckbox,
        blocksCheckbox,
        userHasSubscription,
        sectorFilter,
        activeTags,
        isolatedSymbol,
        expirationUnixStart,
        expirationUnixEnd,
    ], 250);

    const checkTradeFilter = (trade, today, secondsCalc, thirtyMinAgo) => {
        if (!userHasSubscription && trade.ut > thirtyMinAgo) {
            return false;
        }
        if (activeTags.length > 0 && trade.tg?.length <= 0) {
            return false;
        }
        if (activeTags.length > 0 && trade.tg?.length > 0) {
            if (!activeTags.every(item => trade.tg.includes(item))) {
                return false;
            }
        }

        if (activeFavoriteTickers.length > 0) {
            if (!activeFavoriteTickers.includes(trade.tsy)) {
                return false;
            }
        }

        let yearPrefix = "20";

        let p = trade.p; // prem
        if (isNaN(p)) {
            return false;
        };
        let premiumCheck = ((minPremium ? p >= minPremium : true) && (maxPremium ? p <= maxPremium : true));
        if (!premiumCheck) {
            return false;
        };
        let op = trade.op;
        if (isNaN(op)) {
            return false;
        };

        let OTMCheck = ((minOTM ? op >= minOTM : true) && (maxOTM ? op <= maxOTM : true));
        if (!OTMCheck) {
            return false;
        };

        if (isolatedSymbol != "") {
            if (trade.sym != isolatedSymbol) {
                return false;
            }
        }
        let sc = trade.sc;
        let spot = trade.sp;
        let optionPrice = trade.tp; // trade price
        let mc = trade.mc;
        let cv = trade.cv;
        let oi = trade.oi;
        let eut = trade.eut;
        if (isNaN(sc) || (isNaN(spot) && spot != null)
            || (isNaN(optionPrice) && optionPrice != null)
            || (isNaN(mc) && mc != null)
            || (isNaN(cv) && cv != null)
            || (isNaN(eut) && eut != null)
            || (isNaN(oi) && oi != null)) {
            return false;
        };

        let filterOIMax = parseFloat(maxOI);

        if (filterOIMax >= MAX_OI) {
            filterOIMax = 99999999;
        }

        if (oi > filterOIMax) {
            return false;
        }

        // Check if the trade expiration unix timestamp is within expirationUnixStart and expirationUnixEnd
        if (!(eut >= expirationUnixStart) || !(eut <= expirationUnixEnd)) {
            // console.log("Expiration timestamp filter failed");
            return false;
        }


        // let filterPreviousVolumeMax = parseFloat(maxPreviousVolume);
        let filterCurrentVolumeMax = parseFloat(maxCurrentVolume);
        if (filterCurrentVolumeMax >= MAX_CURRENT_VOLUME) {
            filterCurrentVolumeMax = 9999999;
        }
        if (cv > filterCurrentVolumeMax) {
            return false;
        }

        let parseScoreMin = parseFloat(minScore);
        let parseScoreMax = parseFloat(maxScore);
        let scCheck = ((parseScoreMin ? sc >= parseScoreMin : true) && (parseScoreMax ? sc <= parseScoreMax : true));
        if (!scCheck) {
            return false;
        };



        let parseMinSpot = parseFloat(minSpot);
        let parseMaxSpot = parseFloat(maxSpot);
        let spotCheck = ((parseMinSpot ? spot >= parseMinSpot : true) && (parseMaxSpot ? spot <= parseMaxSpot : true));
        if (!spotCheck) {
            return false;
        };

        let parseMinOptionPrice = parseFloat(minOptionPrice);
        let parseMaxOptionPrice = parseFloat(maxOptionPrice);
        let optionPriceCheck = ((parseMinOptionPrice ? optionPrice >= parseMinOptionPrice : true) && (parseMaxOptionPrice ? optionPrice <= parseMaxOptionPrice : true));
        if (!optionPriceCheck) {
            return false;
        };

        let minTradeSizeCheck = parseFloat(minTradeSize);
        let maxTradeSizeCheck = parseFloat(maxTradeSize);
        let tsCheck = ((minTradeSizeCheck ? trade.ts >= minTradeSizeCheck : true) && (maxTradeSizeCheck ? trade.ts <= maxTradeSizeCheck : true));
        if (!tsCheck) {
            return false;
        };


        // let parseMinVolumeDif = parseFloat(minVolumeDif);
        // let parseMaxVolumeDif = parseFloat(maxVolumeDif);
        // let volumeDifCheck = ((parseMinVolumeDif ? volumeDif >= parseMinVolumeDif : true) && (parseMaxVolumeDif ? volumeDif <= parseMaxVolumeDif : true));
        // if (!volumeDifCheck) {
        //     return false;
        // };

        let parseMinMarketCap = isNaN(parseFloat(minMarketCap)) ? 0 : parseFloat(minMarketCap);
        let parseMaxMarketCap = isNaN(parseFloat(maxMarketCap)) ? 0 : parseFloat(maxMarketCap);
        let mcCheck = ((parseMinMarketCap ? mc >= parseMinMarketCap : true) && (parseMaxMarketCap ? mc <= parseMaxMarketCap : true));
        if (!mcCheck) {
            return false;
        } else if (mcCheck && mc === "N/A") {
            return false;
        }

        let excludeCheck = excludeTickers.length > 0 ? !excludeTickers.includes(trade.tsy) : true;
        if (!excludeCheck) {
            return false;
        };
        let showCheck = onlyShowTickers.length > 0 ? onlyShowTickers.includes(trade.tsy) : true;
        if (!showCheck) {
            return false;
        };

        let parseMinDTE = parseInt(minDTE);
        let parseMaxDTE = parseInt(maxDTE);
        if (isNaN(parseMinDTE)) {
            parseMinDTE = 0;
        }
        if (isNaN(parseMaxDTE)) {
            parseMaxDTE = 9999;
        }

        let fullYearDate = yearPrefix + trade.ed;
        let ed = new Date(fullYearDate);

        let timeDiff = ed - today;

        let daysTillExpiration = Math.ceil(timeDiff / secondsCalc);

        let expirationCheck = ((parseMinDTE ? daysTillExpiration >= parseMinDTE : true) && (parseMaxDTE ? daysTillExpiration <= parseMaxDTE : true));
        if (!expirationCheck) {
            return false;
        };
        let callsCheck = true;
        let putsCheck = true;
        if (!callsCheckBox && trade.ot === "C") {
            return false;
        }
        if (!putsCheckBox && trade.ot === "P") {
            return false;
        }

        if (!aaCheckBox && (trade.se === "Ask" || trade.se === "Above Ask")) {
            return false;
        }
        if (!bbCheckBox && (trade.se === "Bid" || trade.se === "Below Bid")) {
            return false;
        }
        if (!spCheckBox && (trade.se === "Mid")) {
            return false;
        }
        if (!bullishCheckBox && trade.tks === 1) {
            return false;
        }
        if (!bearishCheckBox && trade.tks === -1) {
            return false;
        }
        if (!neutralCheckBox && trade.tks === 0) {
            return false;
        }

        let tradesCheck = true;
        let sweepsCheck = true;
        if (!multilegCheckbox && trade.tt === "MULTI") {
            return false;
        }
        if (!splitsCheckBox && trade.tt === "SPLIT") {
            return false;
        }
        if (!sweepsCheckBox && trade.tt === "SWEEP") {
            return false;
        }
        if (!singlesCheckbox && trade.tt === "SINGLE") {
            return false;
        }
        if (!blocksCheckbox && trade.tt === "BLOCK") {
            return false;
        }
        let sectorCheck;

        if (!sectors.includes(sectorFilter) || sectorFilter === "ALL") {
            sectorCheck = true;
        } else {
            sectorCheck = (trade.s === sectorFilter ? true : false)
        }
        return premiumCheck && OTMCheck && sectorCheck && excludeCheck && showCheck && expirationCheck && scCheck && callsCheck && putsCheck && tradesCheck && sweepsCheck && spotCheck && optionPriceCheck && mcCheck;
    }

    const handleTickerSearchInputChange = (value) => {
        if (value === "") {
            onTickerSearchClear();
        } else {
            setSearchInputValue(value);
        }
    };

    const onTickerSearchEnter = () => {
        setIsolatedSymbol("");
        setOnlyShowTickers([searchInputValue]);
        setTickerForData(searchInputValue);
        setIsSearching(true);
    };

    const onTickerSearchClear = () => {
        setIsolatedSymbol("");
        setTickerForData("");
        setOnlyShowTickers([]);
        setIsSearching(false);
        setSearchInputValue("");
        closeRowDropdown();
    };

    const handleSelectionChange = (newSelectedChips) => {
        setActiveTags(newSelectedChips);
    };


    const handleFavoriteTickerChange = (tickers) => {
        // console.log("Favorite tickers: ", tickers);
        setActiveFavoriteTickers(tickers);
    }

    const handleFavoriteTickerRemove = (ticker) => {
        // console.log("Removing favorite ticker: ", ticker);
        // setActiveFavoriteTickers(activeFavoriteTickers.filter((item) => item !== ticker));
        // print local favorites list tickers
        // console.log("Local favorites list: ", localFavoritesList);
        // remove the ticker from the local favorites list which is a list of strings
        let tempList = localFavoritesList.filter((item) => item !== ticker);
        setLocalFavoritesList(tempList);
        // console.log("Local favorites list after remove: ", tempList);
    }

    const clearFilters = () => {
        setIsolatedSymbol("");
        setMinPremium(9999);
        setMaxPremium(500000000);
        setMinDTE(0);
        setMaxDTE(1200);
        setOnlyShowTickers([]);
        setExcludeTickers([]);
        setMinOTM(-3000);
        setMaxOTM(3000);
        setMinScore(0.0);
        setMaxScore(1.0);
        // setMaxLast7DaysBFVolume(MAX_LAST_7_DAYS_BF_VOLUME);
        // setMaxPrevDayBFVolume(MAX_PREV_DAY_BF_VOLUME);
        setMaxPreviousVolume(MAX_PREVIOUS_VOLUME);
        setMaxCurrentVolume(MAX_CURRENT_VOLUME);
        setMaxOI(MAX_OI);
        setExpirationUnixStart(0);
        setExpirationUnixEnd(99999999999999);
        setMinSpot(0);
        setMaxSpot(7500);
        setMinOptionPrice(0);
        setMaxOptionPrice(1000000);
        setMinTradeSize(0);
        setMaxTradeSize(1000000);
        setMinVolumeDif("");
        setMaxVolumeDif("");
        setCallsCheckBox(true);
        setPutsCheckBox(true);
        setAACheckBox(true);
        setBBCheckBox(true);
        setBullishCheckBox(true);
        setBearishCheckBox(true);
        setNeutralCheckBox(true);
        setSpCheckBox(true);
        setSweepsCheckBox(true);
        setSplitsCheckBox(true);
        setSinglesCheckBox(true);
        setBlocksCheckBox(true);
        setMinMarketCap(0);
        setMaxMarketCap(99999999999999);
        setSectorFilter("ALL");
        setActiveTags([]);
        setActiveFavoriteTickers([]);
        setExpirationStartDate(null);
        setExpirationEndDate(null);
        onTickerSearchClear();
        gridApiRef.current.ensureIndexVisible(0, 'top');
        toast.success('Filters cleared');
    };


    const handleSentimentBoxChange = (value, sentimentBox) => {
        if (sentimentBox === "bullishCheckBox") {
            setBullishCheckBox(value);
        } else if (sentimentBox === "bearishCheckBox") {
            setBearishCheckBox(value);
        } else if (sentimentBox === "neutralCheckBox") {
            setNeutralCheckBox(value);
        }
        addEventToFirestore("handleSentimentBoxChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleSentimentBoxChange', {
            'event_category': 'Button',
            'event_label': 'handleSentimentBoxChange',
            'value': 1
        });
    }

    const handleExecutionPriceBoxChange = (value, executionBox) => {
        if (executionBox === "aaCheckBox") {
            setAACheckBox(value);
        } else if (executionBox === "bbCheckBox") {
            setBBCheckBox(value);
        } else {
            setSpCheckBox(value);
        }
        addEventToFirestore("handleExecutionPriceBoxChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleExecutionPriceBoxChange', {
            'event_category': 'Button',
            'event_label': 'handleExecutionPriceBoxChange',
            'value': 1
        });
    }
    const handleTradeSweepChange = (value, tradeOrSweepBox) => {
        if (tradeOrSweepBox === "splitsCheckbox") {
            setSplitsCheckBox(value);
        } else if (tradeOrSweepBox === "blocksCheckbox") {
            setBlocksCheckBox(value);
        } else if (tradeOrSweepBox === "sweepsCheckbox") {
            setSweepsCheckBox(value);
        } else if (tradeOrSweepBox === "multiLegCheckbox") {
            setMultilegCheckbox(value);
        }

        addEventToFirestore("handleTradeSweepChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleTradeSweepChange', {
            'event_category': 'Button',
            'event_label': 'handleTradeSweepChange',
            'value': 1
        });
    }

    const handleFilterNameChange = (value) => {
        setFilterNameValue(value);
    };

    const handleAlertNameChange = (value) => {
        setAlertNameValue(value);
        addEventToFirestore("handleAlertNameChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleAlertNameChange', {
            'event_category': 'Button',
            'event_label': 'handleAlertNameChange',
            'value': 1
        });
    };


    const handleOTMFilterChange = (field, value) => {
        if (field === 'min OTM') {
            setMinOTM(value);
        } else if (field === 'max OTM') {
            setMaxOTM(value);
        } else {
            console.error("Filter error 76090");
        }
    }
    const handleScoreFilterChange = (field, value) => {
        if (field === 'min Score') {
            setMinScore(value);
        } else if (field === 'max Score') {
            setMaxScore(value);
        } else {
            console.error("Filter error 77155");
        }
    }

    // const handleLast7DaysBFVolumeFilterChange = (value) => {
    //     setMaxLast7DaysBFVolume(value);
    // }

    // const handlePrevDayBFVolumeFilterChange = (value) => {
    //     setMaxPrevDayBFVolume(value);
    // }

    const handlePreviousVolumeFilterChange = (value) => {
        setMaxPreviousVolume(value);
    }

    const handleCurrentVolumeFilterChange = (value) => {
        setMaxCurrentVolume(value);
    }
    const handleOIFilterChange = (value) => {
        setMaxOI(value);
    }

    const handleSpotFilterChange = (field, value) => {
        if (field === 'min spot') {
            setMinSpot(value);
        } else if (field === 'max spot') {
            setMaxSpot(value);
        } else {
            console.error("Filter error 70155");
        }
    }

    const handleOptionPriceFilterChange = (field, value) => {
        if (field === 'min option price') {
            setMinOptionPrice(value);
        } else if (field === 'max option price') {
            setMaxOptionPrice(value);
        } else {
            console.error("Filter error 70115");
        }
    }

    const handleTradeSizeFilterChange = (field, value) => {
        // console.log(`Field: ${field}, Value: ${value}`);
        if (field === 'min trade size') {
            setMinTradeSize(value);
        } else if (field === 'max trade size') {
            setMaxTradeSize(value);
        } else {
            console.error("Filter error 202455");
        }
    }


    const handleMarketCapFilterChange = (field, value) => {
        if (field === 'min market cap') {
            setMinMarketCap(value);
        } else if (field === 'max market cap') {
            setMaxMarketCap(value);
        } else {
            console.error("Filter error 70199");
        }
    }

    const handleExpirationFilterChange = (field, value) => {
        if (field === 'min DTE') {
            setMinDTE(value);
        } else if (field === 'max DTE') {
            setMaxDTE(value);
        }
    };
    const handleCallPutChange = (value, callOrPutBox) => {
        if (callOrPutBox === "callsCheckbox") {
            setCallsCheckBox(value);
        } else {
            setPutsCheckBox(value);
        }
        addEventToFirestore("handleCallPutChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleCallPutChange', {
            'event_category': 'Button',
            'event_label': 'handleCallPutChange',
            'value': 1
        });
    };
    const handlePremiumFilterChange = (field, value) => {
        if (field === 'min') {
            setMinPremium(value);
        } else if (field === 'max') {
            setMaxPremium(value);
        } else {
            console.error("Error code 55312");
        }
    };
    const handleSectorDropDownChange = (value) => {
        setSectorFilter(value);
        addEventToFirestore("handleSectorDropDownChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleSectorDropDownChange', {
            'event_category': 'Button',
            'event_label': 'handleSectorDropDownChange',
            'value': 1
        });
    };

    const handleDropdownChange = (e) => {
        const value = e.target.value;
        const selectedItem = filterItems[value];
        if (selectedItem) {
            setMinPremium(selectedItem.minPremium);
            setMaxPremium(selectedItem.maxPremium);
            setMinDTE(selectedItem.minDTE);
            setMaxDTE(selectedItem.maxDTE);
            setOnlyShowTickers(selectedItem.onlyShowTickers);
            setExcludeTickers(selectedItem.excludeTickers);
            setMinOTM(selectedItem.minOTM);
            setMaxOTM(selectedItem.maxOTM);
            setMinScore(selectedItem.minScore);
            setMaxScore(selectedItem.maxScore);
            // setMaxLast7DaysBFVolume(selectedItem.maxLast7DaysBFVolume);
            // setMaxPrevDayBFVolume(selectedItem.maxPrevDayBFVolume);
            setMaxPreviousVolume(selectedItem.maxPreviousVolume);
            setMaxCurrentVolume(selectedItem.maxCurrentVolume);
            setMaxOI(selectedItem.maxOI);
            setExpirationUnixStart(selectedItem.expirationUnixStart ? selectedItem.expirationUnixStart : 0);
            setExpirationUnixEnd(selectedItem.expirationUnixEnd ? selectedItem.expirationUnixEnd : 99999999999999);
            setCallsCheckBox(selectedItem.callsCheckBox);
            setPutsCheckBox(selectedItem.putsCheckBox);
            setAACheckBox(selectedItem.aaCheckBox);
            setBBCheckBox(selectedItem.bbCheckBox);
            setBullishCheckBox(selectedItem.bullishCheckBox);
            setBearishCheckBox(selectedItem.bearishCheckBox);
            setNeutralCheckBox(selectedItem.neutralCheckBox);
            setSpCheckBox(selectedItem.spCheckBox);
            setSweepsCheckBox(selectedItem.sweepsCheckBox);
            setSplitsCheckBox(selectedItem.splitsCheckBox);
            setMultilegCheckbox(selectedItem.multilegCheckbox);
            setBlocksCheckBox(selectedItem.blocksCheckbox);
            setSinglesCheckBox(selectedItem.singlesCheckbox);
            setMinSpot(selectedItem.minSpot);
            setMaxSpot(selectedItem.maxSpot);
            setSectorFilter(selectedItem.sector);
            setActiveTags(selectedItem.activeTags ? selectedItem.activeTags : []); // If there are no active tags, set it to an empty array
            setFilterSelectValue(value);
        } else {
            console.error(`No filter item found with key: ${value}`);
        }
        addEventToFirestore("handleFilterDropdownChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleFilterDropdownChange', {
            'event_category': 'Button',
            'event_label': 'handleFilterDropdownChange',
            'value': 1
        });
    };
    const handleTickerFilterChange = (field, value) => {
        const valueWithCommas = value.replace(/\s+/g, ',');
        const tickerArray = valueWithCommas.toUpperCase().split(',').map(ticker => ticker.trim());
        if (field === 'exclude') {
            if (valueWithCommas.trim() === '') {
                setExcludeTickers([]);
            } else {
                setExcludeTickers(tickerArray);
            }
        } else if (field === 'onlyShow') {
            if (valueWithCommas.trim() === '') {
                setOnlyShowTickers([]);
            } else {
                setOnlyShowTickers(tickerArray);
            }
        }
    };
    // const trendingAlertsButtonClick = () => {
    //     addEventToFirestore("Trending alerts button clicked", user && user.email ? user.email : "");
    //     window.gtag('event', 'Trending alerts button clicked', {
    //         'event_category': 'Button',
    //         'event_label': 'Trending alerts button clicked',
    //         'value': 1
    //     });
    //     // First check if isFilterDrawerOpen is true, if so, close it
    //     if (isFilterDrawerOpen) {
    //         setIsFilterDrawerOpen(false);
    //         setTimeout(() => {
    //             setIsTrendingAlertsOpen(!isTrendingAlertsOpen);
    //         }, 1000);
    //     } else {
    //         setIsTrendingAlertsOpen(!isTrendingAlertsOpen);
    //     }
    //     // pause for 1 second to allow the drawer to close

    // };
    async function downloadCollectionsAsCSV() {
        toast('Downloading', {
            duration: 7500,
            style: {
                background: 'black',
                color: 'white',
            },
            icon: <Download size={18} />,
        });
        const today = new Date();
        const thirtyMinAgo = (today.getTime() / 1000) - (30 * 60);
        // setIsDownloadingCsv(true);
        let counter = parseInt(localStorage.getItem('downloadCSVCounter')) || 0;
        if (counter > 50) {
            console.log("Download limit reached.");
            // setIsDownloadingCsv(false);
            return; // Stop the function if the limit is exceeded
        }

        localStorage.setItem('downloadCSVCounter', counter + 1);

        window.gtag('event', 'downloadCSV', {
            'event_category': 'Button',
            'event_label': 'downloadCSV',
            'value': 1
        });

        const fields = "Symbol, cv, eod_performance, en, ed, iv, mc, oi, ot, op, p, sc, s, sp, se, stp, tsy, tks, tm, tp, ts, tt, ut";
        const fieldsArray = fields.split(', ').map(item => item.trim());

        let allData = [];

        try {
            // Filter the trades first
            const filteredTrades = trades.filter(trade => checkTradeFilter(trade, today, secondsCalc, thirtyMinAgo));

            let data = filteredTrades.map(rowData => {
                // Populate eod_performance with "N/A" if it does not exist
                if (!rowData.hasOwnProperty('eod_performance')) {
                    rowData['eod_performance'] = 'N/A';
                }

                // Iterate over each key in the document and replace commas in its value
                Object.keys(rowData).forEach(key => {
                    if (typeof rowData[key] === 'string') {
                        rowData[key] = rowData[key].replace(/,/g, ' '); // Replace commas with a space
                    }
                });
                return rowData;
            });

            if (fieldsArray) {
                data = data.map(row => {
                    return fieldsArray.reduce((acc, key) => {
                        acc[key] = row[key] || 'N/A'; // Ensure all fields are populated
                        return acc;
                    }, {});
                });
            }

            allData = allData.concat(data);

            // Convert to CSV
            const csvContent = convertToCSV(allData);

            // Trigger CSV download
            triggerCSVDownload(csvContent, `downloaded_alg2_trades.csv`);
            // setIsDownloadingCsv(false);

        } catch (error) {
            // setIsDownloadingCsv(false);
            console.error("Error handling local data: ", error);
        }
    }

    const fetchFilters = async () => {
        try {
            if (user != null) {
                const querySnapshot = await getDocs(query(
                    collection(firestore, 'custom_filters'),
                    where('userEmail', '==', user && user.email ? user.email : ""))
                );
                let fetchedItems = {};
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    fetchedItems[data.filterName] = { ...data, id: doc.id };
                });
                setFilterItems(fetchedItems);
                const filterNames = Object.keys(fetchedItems);
                setFilterNames(filterNames);
            } else {
                console.error("User error 183801");
            }
        } catch (error) {
            console.error('Error code 61838: ', error);
        }
    };
    const handleDateRangeChange = (dates, dateStrings) => {
        if (!dates) {
            console.log('No dates selected');
            return;
        }

        if (dates) {
            setExpirationStartDate(dates[0]);
            setExpirationEndDate(dates[1]);
        } else {
            setExpirationStartDate(null);
            setExpirationEndDate(null);
        }
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);

        // console.log(`Dates selected: ${startDate.toString()} to ${endDate.toString()}`);

        // Convert to Unix timestamps in UTC
        const expirationUnixStart = Math.floor(startDate.getTime() / 1000); // Get Unix timestamp in seconds
        const expirationUnixEnd = Math.floor(endDate.getTime() / 1000);   // Get Unix timestamp in seconds

        // Set the state for expirationUnixStart and expirationUnixEnd
        setExpirationUnixStart(expirationUnixStart);
        setExpirationUnixEnd(expirationUnixEnd);
        // console.log(`Expiration Unix Start: ${expirationUnixStart}, Expiration Unix End: ${expirationUnixEnd}`);

        // console.log('Expiration Unix Start:', expirationUnixStart);
        // console.log('Expiration Unix End:', expirationUnixEnd);
    };


    const deleteFilter = async (filterName) => {
        window.gtag('event', 'Delete filter', {
            'event_category': 'Button',
            'event_label': 'Delete filter',
            'value': 1
        });
        addEventToFirestore("Delete filter", user && user.email ? user.email : "");

        try {
            if (user != null) {
                const filterDocId = filterItems[filterName].id;

                if (!filterDocId) {
                    console.error('Document ID not found for the filter:', filterName);
                    return;
                }

                const filterDoc = doc(firestore, 'custom_filters', filterDocId);
                await deleteDoc(filterDoc);

                setFilterItems(prevItems => {
                    const updatedItems = { ...prevItems };
                    delete updatedItems[filterName];
                    return updatedItems;
                });

                setFilterNames(prevNames => prevNames.filter(name => name !== filterName));
                setSelectedItem('Filters');
                clearFilters();
                fetchFilters();
            } else {
                console.error("User error 567391");
            }
        } catch (error) {
            console.error('Error deleting filter: ', error);
        }
    };

    const handleAlertDelete = async (alertName) => {
        // Update the state without the deleted alert
        setAlertItems(currentAlertItems => {
            const { [alertName]: value, ...remainingAlerts } = currentAlertItems;
            return remainingAlerts;
        });
        const updatedAlertItems = { ...alertItemsRef.current };
        delete updatedAlertItems[alertName];
        alertItemsRef.current = updatedAlertItems;
    };

    const handleSave = async () => {
        addEventToFirestore("handleSave", user && user.email ? user.email : "");
        window.gtag('event', 'handleSave', {
            'event_category': 'Button',
            'event_label': 'handleSave',
            'value': 1
        });
        if (user != null) {
            const docData = {
                filterName: filterNameValue,
                userEmail: user.email,
                minPremium: minPremium,
                maxPremium: maxPremium,
                excludeTickers: excludeTickers,
                onlyShowTickers: onlyShowTickers,
                minDTE: minDTE,
                maxDTE: maxDTE,
                minOTM: minOTM,
                maxOTM: maxOTM,
                minScore: minScore,
                maxScore: maxScore,
                // maxLast7DaysBFVolume: maxLast7DaysBFVolume,
                // maxPrevDayBFVolume: maxPrevDayBFVolume,
                maxPreviousVolume: maxPreviousVolume,
                maxCurrentVolume: maxCurrentVolume,
                maxOI: maxOI,
                expirationUnixStart: expirationUnixStart,
                expirationUnixEnd: expirationUnixEnd,
                callsCheckBox: callsCheckBox,
                putsCheckBox: putsCheckBox,
                aaCheckBox: aaCheckBox,
                bbCheckBox: bbCheckBox,
                bullishCheckBox: bullishCheckBox,
                bearishCheckBox: bearishCheckBox,
                neutralCheckBox: neutralCheckBox,
                spCheckBox: spCheckBox,
                singlesCheckbox: singlesCheckbox,
                sweepsCheckBox: sweepsCheckBox,
                splitsCheckBox: splitsCheckBox,
                multilegCheckbox: multilegCheckbox,
                blocksCheckbox: blocksCheckbox,
                minSpot: minSpot,
                maxSpot: maxSpot,
                minOptionPrice: minOptionPrice,
                maxOptionPrice: maxOptionPrice,
                minTradeSize: minTradeSize,
                maxTradeSize: maxTradeSize,
                minVolumeDif: minVolumeDif,
                maxVolumeDif: maxVolumeDif,
                minMarketCap: minMarketCap,
                maxMarketCap: maxMarketCap,
                sector: sectorFilter,
                activeTags: activeTags,
                timestamp: serverTimestamp()
            };

            try {
                await addDoc(collection(firestore, 'custom_filters'), docData);
                toast.success('Filter saved successfully');
            } catch (error) {
                toast('Error saving filter');
                console.error('Error code 81723: ', error);
            }
            closeSaveDialog();
            fetchFilters();
        } else {
            auth.signOut();
            navigate('/');
        }
        setFilterNameValue("");
    };

    const saveAlert = async () => {
        addEventToFirestore(`Save alert ${alertNameValue}`, user && user.email ? user.email : "");
        window.gtag('event', 'Create alert button', {
            'event_category': 'Button',
            'event_label': 'Create alert',
            'value': 1
        });
        if (user != null) {
            const docData = {
                alertName: alertNameValue,
                userEmail: user.email,
                minPremium: minPremium,
                maxPremium: maxPremium,
                excludeTickers: excludeTickers,
                onlyShowTickers: onlyShowTickers,
                minDTE: minDTE,
                maxDTE: maxDTE,
                minOTM: minOTM,
                maxOTM: maxOTM,
                minScore: minScore,
                maxScore: maxScore,
                // maxLast7DaysBFVolume: maxLast7DaysBFVolume,
                // maxPrevDayBFVolume: maxPrevDayBFVolume,
                maxPreviousVolume: maxPreviousVolume,
                maxCurrentVolume: maxCurrentVolume,
                maxOI: maxOI,
                expirationUnixStart: expirationUnixStart,
                expirationUnixEnd: expirationUnixEnd,
                callsCheckBox: callsCheckBox,
                putsCheckBox: putsCheckBox,
                aaCheckBox: aaCheckBox,
                bbCheckBox: bbCheckBox,
                bullishCheckBox: bullishCheckBox,
                bearishCheckBox: bearishCheckBox,
                neutralCheckBox: neutralCheckBox,
                spCheckBox: spCheckBox,
                singlesCheckbox: singlesCheckbox,
                sweepsCheckBox: sweepsCheckBox,
                splitsCheckBox: splitsCheckBox,
                blocksCheckbox: blocksCheckbox,
                multilegCheckbox: multilegCheckbox,
                minSpot: minSpot,
                maxSpot: maxSpot,
                minOptionPrice: minOptionPrice,
                maxOptionPrice: maxOptionPrice,
                minTradeSize: minTradeSize,
                maxTradeSize: maxTradeSize,
                minVolumeDif: minVolumeDif,
                maxVolumeDif: maxVolumeDif,
                minMarketCap: minMarketCap,
                maxMarketCap: maxMarketCap,
                sector: sectorFilter,
                activeTags: activeTags,
                timestamp: serverTimestamp()
            };
            try {
                await addDoc(collection(firestore, '_custom_alerts'), docData);
            } catch (error) {
                console.error('Error code 81723: ', error);
            }
            closeSaveAlertDialog();
            toast('Alert saved successfully');
            fetchAlerts();
        } else {
            console.error("User error 274818");
        }
        setAlertNameValue("");
    }
    const closeTradeInfo = () => {
        setTradeInfoDialog(false);
    };
    const toggleSaveDialog = () => {
        setOpenSaveDialog(true);
    };
    const closeSaveDialog = () => {
        setOpenSaveDialog(false);
    };
    const toggleSaveAlertDialog = () => {
        setOpenSaveAlertDialog(true);
    };
    const closeSaveAlertDialog = () => {
        setOpenSaveAlertDialog(false);
    };
    const closeSpotTradingView = () => {
        setOpenSpotTradingView(false);
    };
    const closeOptionPriceChart = () => {
        setOpenOptionPriceChart(false);
    };
    const closeNetPremiumChart = () => {
        setOpenNetPremiumChart(false);
    };
    const closeAccountSettings = () => {
        setAccountSettingsDialog(false);
    };
    const NoRowsTemplate = ({ loading, isFiltering }) => {
        const tradingHours = isTradingDay(today) && isTradingHours();

        let template = ``;

        if (isFiltering) {
            template = `
            <div style="padding: 10px; border-radius: 8px; background-color: #333; color: white; text-align: center;">
              No trades matching the filters.
            </div>
          `;
        } else if (!isLoading) {
            template = `
            <div style="padding: 10px; border-radius: 8px; background-color: #333; color: white; text-align: center;">
              ${!tradingHours ? 'Waiting for market open.' : 'No trades matching the filters.'}
            </div>
          `;
        } else {
            template = `
            <div style="padding: 10px; border-radius: 8px; background-color: #333; color: white; text-align: center;">
              Loading...
            </div>
          `;
        }

        return template;
    };
    const isPhoneSize = useMediaQuery({ query: '(max-width: 768px)' });
    const getRowClass = useCallback((params) => {
        const { ic = false, ios = false } = params.data;

        if (ic) {
            return 'canceled-row';
        }
        if (ios) {
            return 'out-of-sequence-row';
        }
        return '';
    }, []);

    // Define the handler functions outside of JSX
    const handleSubmitFeatureRequest = useCallback(() => {
        window.gtag('event', 'Suggest a request button', {
            event_category: 'Button',
            event_label: 'Suggest a request',
            value: 1,
        });
        addEventToFirestore('Click Suggest request', user?.email || '');
        window.location.href = 'mailto:support@bullflow.io?subject=New Feature Suggestion';
    }, [user]);

    const handleContactClick = useCallback(() => {
        window.location.href = 'mailto:support@bullflow.io';
    }, []);

    const handlePaymentPortalClick = useCallback(() => {
        window.gtag('event', 'Payment portal button', {
            event_category: 'Button',
            event_label: 'Payment portal',
            value: 1,
        });
        addEventToFirestore('Click Payment Portal', user?.email || '');
        window.open('https://billing.stripe.com/p/login/00g4jA2096o86FW288', '_blank');
    }, [user]);

    const handleSignOutClick = useCallback(() => {
        window.gtag('event', 'Sign out button', {
            event_category: 'Button',
            event_label: 'Sign out',
            value: 1,
        });
        addEventToFirestore('Click Sign out', user?.email || '');
        auth
            .signOut()
            .then(() => {
                setUserHasSubscription(false);
                navigate('/');
            })
            .catch((error) => {
                console.error('User error 22200', error);
                setUserHasSubscription(false);
                navigate('/');
            });
    }, [auth, navigate, setUserHasSubscription, user]);
    // useEffect(() => {
    //     const checkUserHasSeenAd = async () => {
    //         if (user) {
    //             // console.log("Checking user popup status")
    //             const userEmail = user.email;
    //             const paymentsCollection = collection(firestore, 'payments');
    //             const q = query(paymentsCollection, where('user', '==', userEmail));

    //             const querySnapshot = await getDocs(q);

    //             if (!querySnapshot.empty) {
    //                 // Assuming one payment document per user
    //                 // console.log('Payment document exists for user');
    //                 const paymentDoc = querySnapshot.docs[0];
    //                 const paymentData = paymentDoc.data();
    //                 if (!paymentData.hasSeenDiscordAd1) {
    //                     // console.log('User has not seen Discord ad');
    //                     openDiscordModal();
    //                     await updateDoc(paymentDoc.ref, { hasSeenDiscordAd1: true });
    //                 }
    //             } else {
    //                 // console.log('No payment document exists for user');
    //                 // If no payment document exists for the user, create one and show the modal
    //                 const newPaymentDocRef = doc(paymentsCollection); // Auto-generated ID
    //                 await setDoc(newPaymentDocRef, { user: userEmail, hasSeenDiscordAd1: true });
    //                 openDiscordModal();
    //             }
    //         } else {
    //             console.log('User not logged in');
    //         }
    //     };

    //     checkUserHasSeenAd();
    // }, [user]);

    const openDiscordModal = () => {
        setIsDiscordModalOpen(true);
    };
    const closeDiscordModalAndOpenDiscord = () => {
        window.open('https://discord.gg/mZ6JwbsRH7', '_blank');
        setIsDiscordModalOpen(false);
    };



    if (isPhoneSize) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', background: '#333', borderRadius: '10px', margin: '20px' }}>
                <div style={{ textAlign: 'center' }}>
                    <img src={Bull} alt="Bullflow Logo" style={{ width: '50px', height: '50px', display: 'block', margin: '0 auto 20px' }} />
                    <p style={{ color: '#f0f0f0', fontSize: '1.2em', margin: '0' }}>
                        Bullflow mobile experience is in development. To experience the best of Bullflow, please use a desktop browser.
                    </p>
                </div>
            </div>
        );
    } else {

        return (

            <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'row', background: '#1a1b20' }}>
                <style>
                    {`
          .canceled-row {
            background-color: #ff525840;
          }
          .out-of-sequence-row {
            background-color: #42f5d425;
          }
        `}
                </style>
                <Helmet>
                    <title>Flow</title>
                    <meta name="description" content="Bullflow real time options flow." />
                </Helmet>
                <Toaster
                    toastOptions={{
                        style: {
                            background: '#333',
                            color: 'white'
                        }
                    }} />
                {/* Left nav column with logo and icons */}
                <Sidebar activeItem="flow" />

                {/* Center column with Title row, details, Ag grid */}
                <div style={{ backgroundColor: '#1a1b20', flex: 1, marginRight: '20px', marginTop: '10px', marginLeft: '20px', marginBottom: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {
                        cancelAt !== null && (
                            <Alert
                                message={`Your subscription will expire in ${cancelAt} days. Keep your access to powerful real-time options flow by renewing you subscription! Enjoy 35% off your first month with code: RENEW35`}
                                type="warning"
                                showIcon
                                style={{ width: '100%', textAlign: 'center', marginBottom: '4px', marginTop: '4px' }}
                                closable
                                action={
                                    <AntButton
                                        style={{ backgroundColor: '#FFD700', color: '#000', marginRight: '10px' }}
                                        onClick={() => {
                                            window.gtag('event', 'Renew Subscription portal button', {
                                                'event_category': 'Button',
                                                'event_label': 'Renew Subscription portal button',
                                                'value': 1
                                            });
                                            addEventToFirestore("Renew Subscription portal button", user && user.email ? user.email : "");
                                            window.open(`https://billing.stripe.com/p/login/00g4jA2096o86FW288?prefilled_email=${user && user.email ? user.email : ""}`, '_blank');
                                        }} size="small" type="primary">
                                        Renew
                                    </AntButton>
                                }
                            />
                        )
                    }
                    {/* Top Bar */}
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 0, padding: 0 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                            <h1 style={{ color: 'white', margin: 0, fontWeight: 'bold', fontSize: '1.35em' }}>Options Flow</h1>
                            <PulsingDot showLoading={fetchingRemainingTrades} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                            <TickerSearchBox
                                onChange={handleTickerSearchInputChange}
                                onEnter={userHasSubscription ? onTickerSearchEnter : showSignupNotif}
                                onClear={onTickerSearchClear}
                                initialInputValue={searchInputValue}
                            />
                            <ClearIsolatedContractButton
                                onClick={() => setIsolatedSymbol("")}
                                isolatedSymbol={isolatedSymbol}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                            <DownloadIconButton onClick={() => downloadCollectionsAsCSV()} />
                            <SoundIconButton onClick={() => setIsSoundOn(!isSoundOn)} isSoundOn={isSoundOn} />
                            <ResetIconButton onClick={clearFilters} />
                            <FilterIconButton onClick={userHasSubscription ? toggleDrawer : showSignupNotif} />
                        </div>
                    </div>

                    {/* 
                    <div style={{ marginTop: '10px', width: '100%', height: '140px', display: 'flex', flexDirection: 'row', gap: '10px', borderRadius: '10px', position: 'relative', overflow: 'hidden', flexShrink: 0, minHeight: '140px', minWidth: '100%' }}>
                        {userHasSubscription ? (
                            <MarketDetailsComponent isSearching={isSearching} ticker={tickerForData} />
                        ) : (
                            <NoSubTopBarComponent />
                        )}
                        <ChipFilterComponent selectedChips={activeTags} onSelectionChange={userHasSubscription ? handleSelectionChange : showSignupNotif} />
                    </div> */}

                    {/* Detail bar: */}
                    <div style={{ marginTop: '10px', width: '100%', height: '100px', display: 'flex', flexDirection: 'row', gap: '10px', borderRadius: '10px', position: 'relative', overflow: 'hidden', minHeight: '100px' }}>
                        {userHasSubscription ? (
                            <MarketDetailsComponent isSearching={isSearching} ticker={tickerForData} />
                        ) : (
                            <NoSubTopBarComponent />
                        )}

                        <QuickFilterAndFavoritesComponent
                            selectedQuickFilterChips={activeTags}
                            selectedFavoriteTickerChips={activeFavoriteTickers}
                            onFavoriteTickerSelectionChange={userHasSubscription ? handleFavoriteTickerChange : showSignupNotif}
                            onSelectionChange={userHasSubscription ? handleSelectionChange : showSignupNotif}
                            user={user} localFavoritesList={localFavoritesList}
                            onFavTickerRemoved={handleFavoriteTickerRemove}
                        />
                    </div>


                    {/* Grid */}
                    <div
                        style={gridStyle}
                        className={"ag-theme-quartz-dark"}
                        ref={gridDivRef}
                    >
                        <AgGridReact
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={'columns'}
                            onGridReady={onGridReady}
                            // onColumnMoved={onColumnMoved}
                            getRowNodeId={gridOptions.getRowNodeId}
                            getRowId={gridOptions.getRowId}
                            headerHeight={25}
                            onRowClicked={onRowClicked}
                            ref={gridApiRef}
                            // deltaRowDataMode={true}
                            animateRows={false}
                            getRowClass={getRowClass}
                            overlayNoRowsTemplate={NoRowsTemplate({ isLoading, isFiltering })}
                        />
                        {showRowDropdown && <DropdownMenu position={dropdownPosition} onClose={closeRowDropdown} />}
                    </div>
                </div>

                {/* Second Right Filter Drawer */}

                {/* <div
                style={{
                    width: isTrendingAlertsOpen ? '20%' : '0%',
                    height: '100%',
                    background: '#15181d',
                    transition: 'width 0.3s ease',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    borderTopLeftRadius: '24px',
                    borderBottomLeftRadius: '24px',
                }}
            >
                <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto', marginTop: '10px',
                    marginLeft: '16px',
                    marginRight: '16px',
                }}>
                    <TrendingAlertsView />
                </div>
            </div> */}
                {/* Right Filter Drawer */}
                <div style={{
                    width: isFilterDrawerOpen ? '25%' : '0%', // Control width based on the drawer state
                    height: '100%',
                    background: '#15181d',
                    transition: 'width 0.3s ease',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto', marginTop: '10px' }}>
                        <Tabs
                            aria-label="Little Menu"
                            classNames={{
                                tabList: 'bg-black',
                                cursor: 'bg-neutral-800',
                                tabContent: "group-data-[selected=true]:text-white"
                            }}
                        >
                            <Tab key="Filters" title="Filters">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px', padding: '0 10px' }}>
                                        <TextButton
                                            text="Save as Alert"
                                            onClick={() => {
                                                window.gtag('event', 'Save Alert button', {
                                                    'event_category': 'Button',
                                                    'event_label': 'Save Alert button',
                                                    'value': 1
                                                });
                                                addEventToFirestore(`Click toggle save dialog`, user && user.email ? user.email : "");
                                                toggleSaveAlertDialog();
                                            }}
                                            style={{
                                                fontSize: '.65em',
                                            }}
                                        />
                                        <TextButton
                                            text="Save as Filter"
                                            onClick={() => {
                                                window.gtag('event', 'Toggle Save Dialog button', {
                                                    'event_category': 'Button',
                                                    'event_label': 'Toggle Save Dialog',
                                                    'value': 1
                                                });
                                                addEventToFirestore(`Click toggle save dialog`, user && user.email ? user.email : "");
                                                toggleSaveDialog();
                                            }}
                                            style={{
                                                fontSize: '.65em',
                                            }}
                                        />
                                    </div>
                                </div>
                                <Select
                                    size="sm"
                                    color='default'
                                    label="Select a filter"
                                    placeholder='Select a filter'
                                    selectionMode='single'
                                    onChange={handleDropdownChange}
                                    width="90%"
                                >
                                    {filterNames.map((filter) => (
                                        <SelectItem key={filter} value={filter}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                {filter}
                                                <span
                                                    onClick={() => deleteFilter(filter)}
                                                    style={{ cursor: 'pointer', color: 'red', marginLeft: '20px' }}
                                                >
                                                    Delete
                                                </span>
                                            </div>
                                        </SelectItem>
                                    ))}
                                </Select>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto', height: '162.5vh', gap: '15px', marginTop: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '90%' }}>
                                        <CallPutCheckBoxes callsChecked={callsCheckBox} putsChecked={putsCheckBox} handleCallPutChange={handleCallPutChange} />
                                        <SentimentCheckboxes bullishChecked={bullishCheckBox} bearishChecked={bearishCheckBox} neutralChecked={neutralCheckBox} handleSentimentBoxChange={handleSentimentBoxChange} />
                                        <ExecutionPriceCheckboxes aaChecked={aaCheckBox} bbChecked={bbCheckBox} spChecked={spCheckBox} handleExecutionPriceBoxChange={handleExecutionPriceBoxChange} />
                                        <TradesSweepsCheckBoxes splitsChecked={splitsCheckBox} sweepsChecked={sweepsCheckBox} blocksChecked={blocksCheckbox} multilegChecked={multilegCheckbox} handleTradeSweepChange={handleTradeSweepChange} />
                                    </div>
                                    <TickerFilter onlyShowTickersProp={onlyShowTickers} excludeTickersProp={excludeTickers} handleFilterChange={handleTickerFilterChange} />
                                    <RangePicker
                                        placeholder={["Expir. Start", "Expir. End"]}
                                        style={{
                                            height: '42px',
                                            width: '93.5%',
                                            backgroundColor: '#000'
                                        }}
                                        dropdownClassName="custom-dropdown-class"
                                        value={[expirationStartDate, expirationEndDate]}
                                        onChange={handleDateRangeChange}
                                    />
                                    <PremiumFilter minPremium={minPremium} maxPremium={maxPremium} handleFilterChange={handlePremiumFilterChange} />
                                    <OptionPriceFields minOptionPriceProp={minOptionPrice} maxOptionPriceProp={maxOptionPrice} handleFilterChange={handleOptionPriceFilterChange} />
                                    <TradeSizeFields minTradeSizeProp={minTradeSize} maxTradeSizeProp={maxTradeSize} handleFilterChange={handleTradeSizeFilterChange} />
                                    <CurrentVolumeFilter maxCurrentVolumeProp={maxCurrentVolume} handleFilterChange={handleCurrentVolumeFilterChange} />
                                    <OIFilter maxOIProp={maxOI} handleFilterChange={handleOIFilterChange} />
                                    <ScoreFilterFields minScoreProp={minScore} maxScoreProp={maxScore} handleFilterChange={handleScoreFilterChange} />
                                    <ExpirationFilterFields minDTEProp={minDTE} maxDTEProp={maxDTE} handleFilterChange={handleExpirationFilterChange} />
                                    <OTMFilterFields minOTMProp={minOTM} maxOTMProp={maxOTM} handleFilterChange={handleOTMFilterChange} />
                                    <SpotPriceFields minSpotPriceProp={minSpot} maxSpotPriceProp={maxSpot} handleFilterChange={handleSpotFilterChange} />
                                    <MarketCapFields minMarketCapProp={minMarketCap} maxMarketCapProp={maxMarketCap} handleFilterChange={handleMarketCapFilterChange} />
                                    {/* <Last7DaysBullflowVolume maxLast7DaysBFVolumeProp={maxLast7DaysBFVolume} handleFilterChange={handleLast7DaysBFVolumeFilterChange} />
                                    <PrevDayBullflowVolume maxLastPrevDayBFVolumeProp={maxPrevDayBFVolume} handleFilterChange={handlePrevDayBFVolumeFilterChange} /> */}
                                </div>
                            </Tab>
                            <Tab key="Alerts" title="Alerts" style={{ width: '100%' }}>
                                <div>
                                    <AlertsListView email={user && user.email ? user.email : ""} deleteAlert={handleAlertDelete} />

                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <Modal
                    backdrop="opaque"
                    isOpen={openSaveDialog}
                    onOpenChange={setOpenSaveDialog}
                    radius="lg"
                    classNames={{
                        body: "py-6",
                        backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
                        base: "border-[#292f46] bg-[#111111] text-[#a8b0d3]",
                        header: "border-b-[1px] border-[#292f46]",
                        footer: "border-t-[1px] border-[#292f46]",
                        closeButton: "hover:bg-white/5 active:bg-white/10",
                    }}
                >
                    <ModalContent>
                        <ModalHeader>Save Filter</ModalHeader>
                        <ModalBody>
                            <GenericSearchBox
                                initialInputValue={filterNameValue}
                                onChange={handleFilterNameChange}
                                placeholder="Custom Filter Name"
                                className="w-full"
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={handleSave}>
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal
                    backdrop="opaque"
                    isOpen={openSaveAlertDialog}
                    onOpenChange={setOpenSaveAlertDialog}
                    radius="lg"
                    classNames={{
                        body: "py-6",
                        backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
                        base: "border-[#292f46] bg-[#111111] text-[#a8b0d3]",
                        header: "border-b-[1px] border-[#292f46]",
                        footer: "border-t-[1px] border-[#292f46]",
                        closeButton: "hover:bg-white/5 active:bg-white/10",
                    }}
                >
                    <ModalContent>
                        <ModalHeader>Save Alert</ModalHeader>
                        <ModalBody>
                            <GenericSearchBox
                                initialInputValue={alertNameValue}
                                onChange={handleAlertNameChange}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={saveAlert}>
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <MyDialog open={openSpotTradingView} onClose={closeSpotTradingView} aria-labelledby="Trading View" aria-describedby="trading-view">
                    <DialogContent style={{ background: '#1a1b20', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>
                        <TradingViewWidget symbol={tradingViewTicker} />
                    </DialogContent>
                </MyDialog>
                <MyDialog
                    open={accountSettingsDialog}
                    onClose={closeAccountSettings}
                    aria-describedby="account-settings-description"
                >
                    <DialogContent
                        style={{
                            background: '#1a1b20',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '10px',
                        }}
                    >
                        <div background='rgb(34,26,54)' ref={myDivRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h2 style={{ color: 'white', margin: '0 0 0 0' }}>Welcome</h2>
                        </div>
                    </DialogContent>
                </MyDialog>

                <MyDialog open={tradeInfoDialog} onClose={closeTradeInfo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogContent style={{ background: '#1a1b20', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px', padding: '20px', height: 'auto' }}>
                        <div background='rgb(34,26,54)' ref={myDivRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h2 style={{ color: 'white', margin: '0 0 20px 0' }}>{selectedTickerForInfo && convertOptionsSymbol(selectedTickerForInfo.sym)}</h2>

                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px', width: '100%', marginBottom: '20px' }}>
                                {/* Quadrant 1 */}
                                <div style={{ background: 'black', borderRadius: '10px', padding: '20px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Bid, Ask:</div>
                                        <div style={{ color: 'white' }}>
                                            {tradeDetailsData && tradeDetailsData['cb']}, {tradeDetailsData && tradeDetailsData['ca']}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Mark:</div>
                                        <div style={{ color: 'white' }}>
                                            {tradeDetailsData && (typeof tradeDetailsData['cs'] === 'number' ? tradeDetailsData['cs'].toFixed(2) : 'N/A')}
                                        </div>
                                    </div>


                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Contracts:</div>
                                        <div style={{ color: 'white' }}>{selectedTickerForInfo && `${selectedTickerForInfo.ts} @ ${selectedTickerForInfo.tp}`}</div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>% Return:</div>
                                        <div style={{ color: 'white', display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                                            {selectedTickerForInfo && contractCurrentValue}
                                            {selectedTickerForInfo &&
                                                <span style={{ color: parseFloat(contractPercentChange) >= 0 ? '#02d42c' : '#fa0043', marginLeft: '5px' }}>
                                                    ({parseFloat(contractPercentChange) >= 0 ? '↑' : '↓'}{Math.abs(contractPercentChange)}%)
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>OTM %:</div>
                                        <div style={{ color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.op}%</div>
                                    </div>
                                </div>

                                {/* Quadrant 2 */}
                                <div style={{ background: 'black', borderRadius: '10px', padding: '20px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Premium:</div>
                                        <div style={{ color: '#FFD700', textShadow: '0 0 10px #FFD700' }}>{selectedTickerForInfo && formatMoney(selectedTickerForInfo.p)}</div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Today's Volume:</div>
                                        <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                                            {selectedTickerForInfo && selectedTickerForInfo.cv}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Open Interest:</div>
                                        <div style={{ color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.oi}</div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Implied Volatility:</div>
                                        <div style={{ color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.iv}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ color: 'white' }}>Previous Volume:</div>
                                        <div style={{ color: 'white' }}>{tradeDetailsData && tradeDetailsData['p']}</div>
                                    </div>
                                </div>

                                {/* Quadrant 3 */}
                                <div style={{ background: 'black', borderRadius: '10px', padding: '20px' }}>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Name:   </div>
                                        <div style={{
                                            color: 'white',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '20ch'
                                        }}>
                                            {tradeDetailsData && tradeDetailsData['c']}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Market Cap: </div>
                                        <div style={{ color: 'white' }}>{tradeDetailsData && formatNumber(tradeDetailsData['m'])}</div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Exchange:</div>
                                        <div style={{ color: 'white' }}>{tradeDetailsData && tradeDetailsData['x']}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ color: 'white' }}>Symbol:</div>
                                        <div style={{ color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.sym}</div>
                                    </div>
                                </div>

                                {/* Quadrant 4 */}
                                <div style={{ background: 'black', borderRadius: '10px', padding: '20px' }}>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Delta:</div>
                                        <div style={{ color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.g && selectedTickerForInfo.g.d ? selectedTickerForInfo.g.d.toFixed(3) : '-'}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Theta:</div>
                                        <div style={{ color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.g && selectedTickerForInfo.g.t ? selectedTickerForInfo.g.t.toFixed(3) : '-'}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div style={{ color: 'white' }}>Vega:</div>
                                        <div style={{ color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.g && selectedTickerForInfo.g.v ? selectedTickerForInfo.g.v.toFixed(3) : '-'}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ color: 'white' }}>Rho:</div>
                                        <div style={{ color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.g && selectedTickerForInfo.g.r ? selectedTickerForInfo.g.r.toFixed(3) : '-'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </MyDialog>
                <MyDialog open={openOptionPriceChart} onClose={closeOptionPriceChart} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogContent style={{
                        background: '#26272e',
                        display: 'flex',
                        height: 'auto',
                        width: 'auto',
                    }}>
                        <OptionPriceChart optionsTicker_={optionPriceChartTicker} />
                    </DialogContent>
                </MyDialog>
                <MyDialog open={openNetPremiumChart} onClose={closeNetPremiumChart} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogContent style={{
                        background: '#26272e',
                        display: 'flex',
                        height: 'auto',
                        width: 'auto',
                    }}>
                        <NetPremiumChart optionsTicker_={optionPriceChartTicker} />
                    </DialogContent>
                </MyDialog>
                {/* <Modal
                    backdrop="opaque"
                    isOpen={isDiscordModalOpen}
                    onClose={setIsDiscordModalOpen}
                    radius="lg"
                    classNames={{
                        body: "py-6",
                        backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
                        base: "border-[#292f46] bg-[#111111] text-[#a8b0d3]",
                        header: "border-b-[1px] border-[#292f46]",
                        footer: "border-t-[1px] border-[#292f46]",
                        closeButton: "hover:bg-white/5 active:bg-white/10",
                    }}
                >
                    <ModalContent>
                        <ModalHeader>Join us on Discord!</ModalHeader>
                        <ModalBody>
                            <div>
                                <h2 style={{ color: '#5865F2', marginBottom: '10px', textAlign: 'center' }}>
                                    🎉 Launching the Bullflow Academy Discord Trial Week!
                                </h2>
                                <p style={{ marginBottom: '15px' }}>
                                    We're thrilled to announce the kick-off of the Bullflow Academy Discord trial week! As a Bullflow member, you now have full access to our Discord server included with your subscription!
                                </p>
                                <p style={{ marginBottom: '15px' }}>
                                    🔥 Got questions about the flow you're spotting or need insights on the platform? Our in-house flow trader is on deck! Throughout the trading day, he will be answering your questions and teaching you how to read options flow like an expert.
                                </p>
                                <p style={{ marginBottom: '15px' }}>
                                    🚀 Join us now and be part of a focused community aimed at boosting your flow trading success!
                                </p>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onPress={closeDiscordModalAndOpenDiscord}>
                                Join Server
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal> */}
            </div >
        );
    }
};

async function fetchTradeDetails(optionsTicker, x, docId) {
    try {
        const url = `https://api.bullflow.io/v1/extraDetails?Symbol=${optionsTicker}&x=${x}&id=${docId}&date=${getDateString()}`;
        const response = await fetch(url);
        if (response.ok) {
            return await response.json();
        } else {
            console.error('There was an issue retrieving the trade details.');
            analyticsLogError('There was an issue retrieving the trade details.');
            return null;
        }
    } catch (error) {
        analyticsLogError(`trade details error: ${error}.`);
        console.error('Error gettig trade details 1877636');
        return null;
    }
}

async function fetchOptionsSpread(optionsTicker, oldSpread) {
    try {
        const url = `https://api.bullflow.io/v1/spreadPercentChange?Symbol=${optionsTicker}&spread=${oldSpread}`;
        const response = await fetch(url);
        if (response.ok) {
            return await response.json();
        } else {
            console.error('There was an issue retrieving the options spread.');

            analyticsLogError('There was an issue retrieving the options spread.');
            return null;
        }
    } catch (error) {
        analyticsLogError(`option spread error: ${error}.`);
        console.error('Error:', error);
        return null;
    }
}

async function fetchPreviousVolume(optionsTicker, oldSpread) {
    try {
        const url = `https://api.bullflow.io/v1/previousVolume?Symbol=${optionsTicker}`;
        const response = await fetch(url);
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error 88314 - prev volume fetch error');
            analyticsLogError('There was an issue retrieving the previous volume.');
            return null;
        }
    } catch (error) {
        analyticsLogError(`option spread error: ${error}.`);
        console.error('Error 88314 - prev volume fetch error');
        return null;
    }
}

async function fetchBidAskSpread(optionsTicker) {
    try {
        const url = `https://api.bullflow.io/v1/bas?Symbol=${optionsTicker}`;
        const response = await fetch(url);
        if (response.ok) {
            return await response.json();
        } else {
            console.error('There was an issue retrieving the bid ask spread.');
            analyticsLogError('There was an issue retrieving the bid ask spread.');
            return null;
        }
    } catch (error) {
        analyticsLogError(`option spread error: ${error}.`);
        console.error('Error 88314 - bas fetch error');
        return null;
    }
}


export default MainDashboard;
